import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import SEO, { SeoModel } from '../components/seo';

interface PageNode {
  excerpt: string,
  html: string,
  frontmatter: {
    date: string,
    title: string,
    description: string
  }
}

interface PageTemplateProps {
  location: Location,
  readonly data: {
    markdownRemark: PageNode
  },
}

export const pageTemplateQuery = graphql`
  query PageTemplateQuery($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt(pruneLength: 250)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "nl-NL")
        description
      }
    }
  }
`;

const PageTemplate: React.FC<PageTemplateProps> = ({
  location,
  data
}) => {
  const { markdownRemark } = data;

  const seo: SeoModel = {
    title: markdownRemark.frontmatter.title,
    description: markdownRemark.frontmatter.description || markdownRemark.excerpt
  };

  return (
    <Layout location={location}>
      <SEO model={seo} />
      <div className="mt-5" />
      <section className={`bg-light text-left px-3 py-5`}>
        <div className="container my-5">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
          />
        </div>
      </section>
    </Layout>
  );
}

export default PageTemplate;
